import * as httpCall from "../../../../../Utils/apis/apis";
export const lookupDefaultValue = (data, crmId, currentPage) => {
    var title;
    try {
        var NewData = JSON.parse(currentPage[data.prePopulate])
        title = NewData[0].title;
    } catch (e) {
        title = currentPage[data.prePopulate]
    }
    var id = crmId;
    var label = data && data.destinationVertex ? data.destinationVertex : "";
    var email = "";
    var reqObject = {
        data: {
            id: id,
            label: label,
            title: title,
            email: email,
        },
        edgeLabel: data && data.edgeLabel ? data.edgeLabel : "",
        fieldType: data && data.fieldType ? data.fieldType : "",
    };
    return reqObject;
};
export const asyncCheckbox = {
    fields:[
        "status",
        "Rationale for operational rating"
      ],
      filterOnDateField: "Date",
      label: "asset",
      status:"status",
      Rationale:"Rationale for operational rating"
  };
export const updateStatus = async (d, status, pageUrl) => {
    let payload = {
      id: d,
      currentStatus: status,
      utcEpoch: Math.floor(Date.now() / 1000),
    };
    await httpCall.httpPost(
      `/CRMData/updateDealStatus?pageUrl=${pageUrl}`,
      payload
    );
  };

export const getStateFromLocalStorage = () => {
  try {
    let val = localStorage.getItem("formData") || "";
    if (val) {
      return JSON.parse(val);
    }
    return val;
  } catch {
    return "";
  }
};
export const UpdateFormState = (key, value) => {
  let prevState = getStateFromLocalStorage();
  if (prevState) {
    prevState[key] = value;
  } else {
    prevState = { [key]: value };
  }
  localStorage.setItem("formData", JSON.stringify(prevState));
};

export const getDefaultValue = (element, defValues) => {
  try {
    return {
      ...element,
      defaultValue: defValues[element.id] ? defValues[element.id] : "",
    };
  } catch {
    return element;
  }
};

export const documentsFetched = (documents, fieldName) => {
  let docs = [];
  try {
    docs = JSON.parse(documents).filter((doc) => {
      let fileProp = doc.split("/")[1].split("__");
      if (fileProp[0] === fieldName) {
        return true;
      }
    });
  } catch (e) {
    docs = [];
  }
  return docs;
};

export const monthMapping = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};